/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    strong: "strong",
    blockquote: "blockquote",
    code: "code",
    ul: "ul",
    li: "li",
    hr: "hr",
    h3: "h3",
    br: "br",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    a: "a",
    h4: "h4",
    em: "em"
  }, _provideComponents(), props.components), {Attribute, Boon, Item, BuildLink, Specialization, Skill, Trait, Condition} = _components;
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!BuildLink) _missingMdxReference("BuildLink", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Why is achieving crit cap important?"), "\n", React.createElement(_components.p, null, "Achieving ", React.createElement(Attribute, {
    name: "Critical Chance",
    text: "100% Critical Chance"
  }), " is desirable for most ", React.createElement(_components.strong, null, "power-based"), " Guild Wars 2 builds. As your Critical Damage will be much higher due to large amounts of ", React.createElement(Attribute, {
    name: "Ferocity"
  }), ", the value of ", React.createElement(Attribute, {
    name: "Critical Chance"
  }), " (and therefore ", React.createElement(Attribute, {
    name: "Precision"
  }), ") exceeds other attributes like Power."), "\n", React.createElement(_components.p, null, "At level 80, the conversion formula to calculate ", React.createElement(Attribute, {
    name: "Critical Chance"
  }), " from ", React.createElement(Attribute, {
    name: "Precision"
  }), " is:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "Critical Chance = (Precision - 895) / 21")), "\n"), "\n", React.createElement(_components.p, null, "From the above equation, it follows that ", React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "2995 Precision"
  })), " is enough to reach ", React.createElement(Attribute, {
    name: "Critical Chance",
    text: "100% Critical Chance"
  }), "."), "\n", React.createElement(_components.p, null, "You can subtract the following values in general:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "1000 base Precision"
  })), " at level 80"), "\n", React.createElement(_components.li, null, React.createElement(Attribute, {
    name: "Critical Chance",
    text: "25% Critical Chance"
  }), " from ", React.createElement(_components.strong, null, React.createElement(Boon, {
    name: "Fury"
  })), " ( ", React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "525 Precision"
  })), ")"), "\n"), "\n", React.createElement(_components.p, null, "Which brings it down to ", React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "1470 Precision"
  })), ", without any traits, gear, upgrades or consumables."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "Gear"), "\n", React.createElement(_components.p, null, "Runes and Sigils are our next step to consider increasing ", React.createElement(Attribute, {
    name: "Precision"
  }), " The most obvious choice would be ", React.createElement(Item, {
    name: "Accuracy",
    type: "Sigil",
    id: "24618"
  }), ", but in Fractals we want to take ", React.createElement(Item, {
    name: "Impact",
    type: "Sigil",
    id: "24868"
  }), " (and ", React.createElement(Item, {
    name: "Force",
    type: "Sigil",
    id: "24615"
  }), ") to benefit from frequently broken defiance bars. Which leaves us with item affixes and runes as other sources of ", React.createElement(Attribute, {
    name: "Precision"
  }), "."), "\n", React.createElement(_components.p, null, "A full ascended set of ", React.createElement(_components.strong, null, "Berserker"), " items is equal to ", React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "961 Precision"
  })), " (dual wield) or ", React.createElement(_components.strong, null, React.createElement(Attribute, {
    name: "Precision",
    text: "960 Precision"
  })), " (two-handed).", React.createElement(_components.br), "\n", "So you'll ", React.createElement(Attribute, {
    name: "Precision",
    text: "509 Precision"
  }), " (dual wield) or ", React.createElement(Attribute, {
    name: "Precision",
    text: "510 Precision"
  }), " (two-handed) to reach ", React.createElement(Attribute, {
    name: "Critical Chance",
    text: "100% Critical Chance"
  }), ". The next source of ", React.createElement(Attribute, {
    name: "Precision"
  }), " we should consider our our runes:"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Common Power Rune Choices"), React.createElement(_components.th, null, React.createElement(Attribute, {
    name: "Precision",
    text: "Precision Required Dual Wield"
  })), React.createElement(_components.th, null, React.createElement(Attribute, {
    name: "Precision",
    text: "Precision Required Two-Handed"
  })))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "24836",
    id: "24836"
  })), React.createElement(_components.td, null, "509"), React.createElement(_components.td, null, "510")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "24818",
    id: "24818"
  })), React.createElement(_components.td, null, "209"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Item, {
    id: "24723",
    id: "24723"
  })), React.createElement(_components.td, null, "334"), React.createElement(_components.td, null, "335")))), "\n", React.createElement(_components.p, null, "If your chosen Rune doesn't have you reaching ", React.createElement(Attribute, {
    name: "Critical Chance",
    text: "100% Critical Chance"
  }), ", the next step is to swap Berserker's items (Major: ", React.createElement(Attribute, {
    name: "Power"
  }), "; Minor: ", React.createElement(Attribute, {
    name: "Precision"
  }), ", ", React.createElement(Attribute, {
    name: "Ferocity"
  }), ") for Assassin's (Major: ", React.createElement(Attribute, {
    name: "Precision"
  }), "; Minor: ", React.createElement(Attribute, {
    name: "Power"
  }), ", ", React.createElement(Attribute, {
    name: "Ferocity"
  }), ") to gain more Precision. Additionally some condition builds such as ", React.createElement(BuildLink, {
    build: "Condi Virtuoso",
    specialization: "Virtuoso"
  }), " benefit from incresed ", React.createElement(Attribute, {
    name: "Precision"
  }), ". In this case Viper's items (Major: ", React.createElement(Attribute, {
    name: "Condition Damage"
  }), ", ", React.createElement(Attribute, {
    name: "Power"
  }), "; Minor: ", React.createElement(Attribute, {
    name: "Expertise"
  }), ", ", React.createElement(Attribute, {
    name: "Precision"
  }), ") are swapped for Sinister (Major: ", React.createElement(Attribute, {
    name: "Condition Damage"
  }), "; Minor: ", React.createElement(Attribute, {
    name: "Power"
  }), ", ", React.createElement(Attribute, {
    name: "Precision"
  }), ") and Rampager's (Major: ", React.createElement(Attribute, {
    name: "Precision"
  }), "; Minor: ", React.createElement(Attribute, {
    name: "Power"
  }), ", ", React.createElement(Attribute, {
    name: "Condition Damage"
  }), ") items."), "\n", React.createElement(_components.p, null, "Our final gear choice we can make to increase ", React.createElement(Attribute, {
    name: "Precision"
  }), " is to use Consumables and Infusions. Most power builds use ", React.createElement(Item, {
    id: "91805",
    id: "91805"
  }), " for food. However ", React.createElement(Item, {
    id: "91709",
    id: "91709"
  }), " can be a good choice if you miss a small amount of ", React.createElement(Attribute, {
    name: "Precision"
  }), " to crit cap. Also available to use is ", React.createElement(Item, {
    id: "12486",
    id: "12486"
  }), " can be used but we would recommend taking extra Assassin's items if possible to be able to benefit from Acsended Feasts."), "\n", React.createElement(_components.p, null, "Keep in mind that you can also take ", React.createElement(Item, {
    id: "39621",
    id: "39621"
  }), " (Or its +7 and +9 counterparts), but normally it is easier to calculate with a full set of Power infusions."), "\n", React.createElement(_components.p, null, "Use our ", React.createElement(_components.a, {
    href: "https://optimizer.discretize.eu/"
  }, "Gear Optimizer"), " to calculate the optimal setup of Runes, Sigils, Stats and Consumables."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, null, "Skills/Traits"), "\n", React.createElement(_components.p, null, "While some professions have access to passive signet utility skills increasing Precision by 180 points, there is usually a better DPS skill if you are crit capped already.", React.createElement(_components.br), "\n", "If for some reason your build isn't optimized yet and you are missing at least those 180 points, it is recommended to take the Precision signet instead of the weakest other utility skill."), "\n", React.createElement(_components.p, null, "Apart from that, various traits increase either Precision or Critical Chance directly.", React.createElement(_components.br), "\n", "Some of them will easily put you above 100% Critical Chance so you don't need any Assassin's pieces."), "\n", React.createElement(_components.p, null, "Below are some popular trait choices to push Critical Chance."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Skills increasing Critical Chance"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Profession"), React.createElement(_components.th, null, "Skill"), React.createElement(_components.th, null, "Precision"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Warrior"
  })), React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of Fury",
    id: "14410"
  })), React.createElement(_components.td, null, "180")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Thief"
  })), React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of Agility",
    id: "13062"
  })), React.createElement(_components.td, null, "180")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Elementalist"
  })), React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of Fire",
    id: "5542"
  })), React.createElement(_components.td, null, "180")))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Traits increasing Critical Chance"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Profession"), React.createElement(_components.th, null, "Trait Line"), React.createElement(_components.th, null, "Trait"), React.createElement(_components.th, null, "Condition"), React.createElement(_components.th, null, "Critical Chance"), React.createElement(_components.th, null, "Precision"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Elementalist"
  })), React.createElement(_components.td, null, "Weaver"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Superior Elements",
    id: "2177"
  })), React.createElement(_components.td, null, "Target must have ", React.createElement(Condition, {
    name: "Weakness"
  })), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Guardian"
  })), React.createElement(_components.td, null, "Radiance"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Radiant Power",
    id: "568"
  })), React.createElement(_components.td, null, "Target must have ", React.createElement(Condition, {
    name: "Burning"
  })), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Guardian"
  })), React.createElement(_components.td, null, "Radiance"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Righteous Instincts",
    id: "1683"
  })), React.createElement(_components.td, null, "Player must have ", React.createElement(Boon, {
    name: "Resolution"
  })), React.createElement(_components.td, null, "25%"), React.createElement(_components.td, null, "525")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Guardian"
  })), React.createElement(_components.td, null, "Radiance"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Right-Hand Strength",
    id: "566"
  })), React.createElement(_components.td, null, "-"), React.createElement(_components.td, null, "3.8%"), React.createElement(_components.td, null, "80")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Ranger"
  })), React.createElement(_components.td, null, "Skirmishing"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Hunters Tactics",
    id: "1068"
  })), React.createElement(_components.td, null, "Player must hit from behind or the side"), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Ranger"
  })), React.createElement(_components.td, null, "Skirmishing"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Vicious Quarry",
    id: "1888"
  })), React.createElement(_components.td, null, "Player must have ", React.createElement(Boon, {
    name: "Fury"
  })), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Engineer"
  })), React.createElement(_components.td, null, "Firearms"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Hematic Focus",
    id: "536"
  })), React.createElement(_components.td, null, "Target must have ", React.createElement(Condition, {
    name: "Bleeding"
  })), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Engineer"
  })), React.createElement(_components.td, null, "Firearms"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "High Caliber",
    id: "1914"
  })), React.createElement(_components.td, null, "Target must be closer than 300 units"), React.createElement(_components.td, null, "15%"), React.createElement(_components.td, null, "315")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Thief"
  })), React.createElement(_components.td, null, "Critical Strikes"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Keen Observer",
    id: "1281"
  })), React.createElement(_components.td, null, "Player health must be above 90%"), React.createElement(_components.td, null, "5%"), React.createElement(_components.td, null, "105")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Thief"
  })), React.createElement(_components.td, null, "Critical Strikes"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Twin Fangs",
    id: "1268"
  })), React.createElement(_components.td, null, "Player must hit from behind or the side"), React.createElement(_components.td, null, "7%"), React.createElement(_components.td, null, "147")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Necromancer"
  })), React.createElement(_components.td, null, "Curses"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Target the Weak",
    id: "810"
  })), React.createElement(_components.td, null, "2% per unique condition on target"), React.createElement(_components.td, null, "up 28%"), React.createElement(_components.td, null, "up to 588")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Necromancer"
  })), React.createElement(_components.td, null, "Reaper"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Decimate Defenses",
    id: "2031"
  })), React.createElement(_components.td, null, "2% per stack of ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on target"), React.createElement(_components.td, null, "up to 50%"), React.createElement(_components.td, null, "up to 1050")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Revenant"
  })), React.createElement(_components.td, null, "Invocation"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Roiling Mists",
    id: "1719"
  })), React.createElement(_components.td, null, React.createElement(Boon, {
    name: "Fury"
  }), " has increased effectiveness"), React.createElement(_components.td, null, "25%"), React.createElement(_components.td, null, "525")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Revenant"
  })), React.createElement(_components.td, null, "Renegade"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Brutal Momentum",
    id: "2142"
  })), React.createElement(_components.td, null, "10% which is increased to 33% if the Player has full endurance"), React.createElement(_components.td, null, "10%/33%"), React.createElement(_components.td, null, "693")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Mesmer"
  })), React.createElement(_components.td, null, "Virtuoso"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Quiet Intensity",
    id: "2193"
  })), React.createElement(_components.td, null, "Player must have ", React.createElement(Boon, {
    name: "Fury"
  })), React.createElement(_components.td, null, "10%"), React.createElement(_components.td, null, "210")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Warrior"
  })), React.createElement(_components.td, null, "Strength"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Pinnacle of Strength",
    id: "1453"
  })), React.createElement(_components.td, null, "-"), React.createElement(_components.td, null, "5%"), React.createElement(_components.td, null, "105")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Warrior"
  })), React.createElement(_components.td, null, "Arms"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Furious Burst",
    id: "1342"
  })), React.createElement(_components.td, null, "Player must have ", React.createElement(Boon, {
    name: "Fury"
  })), React.createElement(_components.td, null, "5%"), React.createElement(_components.td, null, "105")))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Traits increasing Precision"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Profession"), React.createElement(_components.th, null, "Specialization"), React.createElement(_components.th, null, "Trait"), React.createElement(_components.th, null, "Note"), React.createElement(_components.th, null, "Precision"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Soulbeast"
  })), React.createElement(_components.td, null, "Beastmastery"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Pack Alpha",
    id: "1900"
  })), React.createElement(_components.td, null, "This will only apply to yourself if you are in ", React.createElement(Skill, {
    name: "Beastmode",
    id: "42944"
  })), React.createElement(_components.td, null, "150")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Thief"
  })), React.createElement(_components.td, null, "Deadeye"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Be Quick or Be Killed",
    id: "2093"
  })), React.createElement(_components.td, null, "Target must be marked"), React.createElement(_components.td, null, "200")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Specialization, {
    name: "Elementalist"
  })), React.createElement(_components.td, null, "Weaver"), React.createElement(_components.td, null, React.createElement(Trait, {
    name: "Elements of Rage",
    id: "2131"
  })), React.createElement(_components.td, null, "Converts 13% of your Vitality to Precision"), React.createElement(_components.td)))), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Note: Conversion traits only take flat modifiers from items, runes, food and infusions into consideration. Things like boons (e.g. ", React.createElement(Boon, {
    name: "Might"
  }), "), traits (e.g. other conversions), skills (e.g. ", React.createElement(Skill, {
    name: "Signet of Fury",
    id: "14410"
  }), ") or the ", React.createElement(Item, {
    id: "79722",
    id: "79722"
  }), " are not included.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
